<template src="./RecommendedCourses.html">
</template>

<script>
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import coursesService from "@/services/Courses";

export default {
  name: "RecommendedCourses",
  components: {
    ArrobaMedellinSeeker
  },
  data() {
    return {
      recommendedCoursesData:
        [
          {
            name: 'Nombre del curso',
            level: 'Pregrado',
            filter: '@Medellin'
          }, {
          name: 'Nombre del curso',
          level: 'Pregrado',
          filter: '@Medellin'
        }, {
          name: 'Nombre del curso',
          level: 'Pregrado',
          filter: '@Medellin'
        }, {
          name: 'Nombre del curso',
          level: 'Pregrado',
          filter: '@Medellin'
        }, {
          name: 'Nombre del curso',
          level: 'Pregrado',
          filter: '@Medellin'
        }, {
          name: 'Nombre del curso',
          level: 'Pregrado',
          filter: '@Medellin'
        }, {
          name: 'Nombre del curso',
          level: 'Pregrado',
          filter: '@Medellin'
        }, {
          name: 'Nombre del curso',
          level: 'Pregrado',
          filter: '@Medellin'
        }, {
          name: 'Nombre del curso',
          level: 'Pregrado',
          filter: '@Medellin'
        }
        ],
      errorMessage: false,

    }
  },
  methods:{
    async getAllCoursesRecommendations(type){
      try {
        this.recommendedCoursesData = await coursesService.getAllCoursesRecommendations(type)
      }catch (e) {
        console.log('e',e);
      }
    },
    async searchByWord(word){
      try {
        this.recommendedCoursesData = await coursesService.getCoursesRecommendationsByWord(word)
      }catch (e) {
        console.log('e',e);
      }
    },
    open(url)
    {
      window.open(url, "_blank");
    },
    deleteRecommendedCourse(courseId) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar curso recomendado',
        message: 'Deberás volver a ingresar la información para que aparezca en el perfil de los estudiantes y Docentes.',
        type: 'is-warning is-outlined',
        cancelText: 'Cancelar',
        confirmText: 'Eliminar',
        size: 'is-small',
        onConfirm: async () => {
          try {
            await coursesService.deleteRecommendedCourse(courseId);
            await this.getAllCoursesRecommendations('@Medellin');
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Curso eliminado exitosamente',
              position: 'is-bottom',
              type: 'is-success'
            })
          } catch (e) {
            this.$buefy.toast.open({
              duration: 5000,
              message: e.response.data.message,
              position: 'is-bottom',
              type: 'is-danger'
            })
          }
        }
      })
    },
  },
  mounted() {
    this.getAllCoursesRecommendations('@Medellin');
  }
}
</script>

<style scoped lang="scss" src="./RecommendedCourses.scss">
.academic-panel {
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
  url(../../assets/img/academico-coltejer-v2.jpg) no-repeat fixed;
  background-size: cover;
  min-height: 100vh;
  width: 100%;

}
</style>
